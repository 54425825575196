import './style.scss';
import { useEffect, useRef } from 'react';
import { FaGithubSquare, FaExternalLinkSquareAlt } from 'react-icons/fa';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import histogram from './histogramChart3';

const Projects = () => {
  const sliderRef = useRef();
  // const section = document.querySelector('.section.projects');
  // if (section) {
  //   section.addEventListener(
  //     'wheel',
  //     function (e) {
  //       if (e.type != 'wheel') {
  //         return;
  //       }
  //       let delta = (e.deltaY || -e.wheelDelta || e.detail) >> 10 || 1;
  //       delta = -delta * 0.3;
  //       section.scrollLeft -= delta;
  //       e.preventDefault();
  //     },
  //     { capture: true, passive: false }
  //   );
  // }
  let slider = document.getElementById('slider');

  const slideLeft = () => {
    let { width } = slider.getBoundingClientRect();
    slider.scrollLeft = slider.scrollLeft - width * 0.8;
  };

  const slideRight = () => {
    let { width } = slider.getBoundingClientRect();

    slider.scrollLeft = slider.scrollLeft + width;
  };

  useEffect(() => {
    histogram();
  }, []);
  // const toggleScroll = (enabled) => {
  //   if (enabled === true) {
  //     document.body.style.overflowY = 'auto';
  //   } else if (enabled === false) {
  //     console.log('disabled');
  //     document.body.style.overflowY = 'hidden';
  //   }
  //   console.log('toggle fired');
  // };

  // const handleProjectsScroll = (e) => {
  //   if (e.deltaY > 0) {
  //     // Scrolling right
  //     slider.scrollLeft = slider.scrollLeft + 100;
  //     let { width } = slider.getBoundingClientRect();
  //     console.log(slider.offsetWidth, width);
  //     //   if (slider.scrollWidth < slider.scrollLeft + slider.offsetWidth + 2) {
  //     //     toggleScroll(true);
  //     //     console.log('1', slider.offsetWidth);
  //     //   } else {
  //     //     toggleScroll(false);
  //     //     console.log('2', e.target);
  //     //   }
  //     // } else if (e.deltaY < 0) {
  //     // Scrolling left
  //     slider.scrollLeft = slider.scrollLeft - 100;
  //     // if scroll left = 0 resume page scroll
  //     // if (slider.scrollLeft === 0) {
  //     //   toggleScroll(true);
  //     //   console.log('3');
  //     // } else if (slider.scrollLeft > 0) {
  //     //   toggleScroll(false);
  //     //   console.log('4');
  //     // }
  //   }
  // };

  return (
    <div className="section projects">
      <MdChevronLeft onClick={slideLeft} />
      <div
        id="slider"
        ref={sliderRef}
        // onWheel={handleProjectsScroll}
        // onMouseEnter={() => toggleScroll(false)}
        // onMouseLeave={() => toggleScroll(true)}
      >
        <div className="project" id="histogram">
          {}
        </div>

        <div className="project" id="wordpress">
          <div className="description">
            <h2>Wordpress Sites</h2>
            <p>Examples of websites developed with Wordpress.</p>
            <ul>
              <li>
                <a href="https://thisiskreist.com">KREIST</a>
              </li>
              <li>
                <a href="https://rskwolin.pl">RSK Wolin</a>
              </li>
              <li>
                <a href="https://pbdietetyk.pl">PB Dietetician</a>
              </li>
            </ul>
          </div>
          <div className="visual">
            <img src="/images/kreistSS.jpg" alt="KREIST screenshot" />
          </div>
        </div>
        <div className="project">
          <div className="decription">
            <h2>Whipmart</h2>

            <p>
              A car marketplace web app. Final project for INFO-5143 course at
              Fanshawe.
            </p>
            <ul>
              <li>React</li>
              {/* <li>Redux</li> */}
              <li>SASS</li>
              <li>Firebase</li>
            </ul>
            <div className="links">
              <a href="https://github.com/sbartnicki/whipmart">
                <FaGithubSquare />
              </a>
              {/* <a href="https://whipmart.vercel.app/">
                <FaExternalLinkSquareAlt />
              </a> */}
            </div>
          </div>
          <div className="visual">
            {/* <img src={require('../../images/whipmartSS.jpg')} /> */}
            <img src="/images/whipmartSS.jpg" alt="Whipmart screenshot" />
          </div>
        </div>
        <div className="project">
          <div className="decription">
            <h2>Notes App</h2>
            <p>
              Runs on Android and iOS, allows to store notes including voice
              memos and images. Can read ntoes using Text-to-Speech API.
            </p>
            <ul>
              <li>React Native</li>
              <li>Expo</li>
              <li>Firebase</li>
            </ul>
            <div className="links">
              <a href="https://github.com/sbartnicki/notes-app">
                <FaGithubSquare />
              </a>
            </div>
          </div>
          <div className="visual">
            <img src="/images/notesSS.jpg" alt="Notes App screenshot" />
          </div>
        </div>
        <div className="project">
          <div className="decription">
            <h2>Snake Game</h2>
            <p>
              Final project for INFO-5144 course at Fanshawe. A snake-like game
              with elements of physics.
            </p>
            <ul>
              <li>React Native Game Engine</li>
              <li>Matter.js Physics Engine</li>
            </ul>
            <div className="links">
              <a href="https://github.com/sbartnicki/snake-like-game">
                <FaGithubSquare />
              </a>
            </div>
          </div>
          <div className="visual">
            <img src="/images/snakeSS.jpg" alt="Snake screenshot" />
          </div>
        </div>
        <div className="project dummy"></div>
      </div>
      <MdChevronRight onClick={slideRight} />
    </div>
  );
};

export default Projects;
