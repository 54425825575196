import { useEffect, useState } from 'react';
import Education from '../Education';
import './style.scss';
import Intro from '../Intro';
import Projects from '../Projects';
import Contact from '../Contact';

let ticking = false;

const LandingPage = () => {
  const [height, setHeight] = useState(300);
  const titles = ['Intro', 'Education', 'Portfolio', 'Contact', 'Contact'];
  const windowHeight = window.visualViewport.height;
  const scrollPosition = window.visualViewport.pageTop;

  const handleIntroClick = () => {
    // setHeight(height + 5);
  };

  // console.log(windowHeight, scrollPosition);

  useEffect(() => {
    const callback = (event) => {
      // console.log(event);
      let newHeight = 300 + Math.round(window.scrollY * 1.1);
      // console.log(newHeight);
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setHeight(newHeight);
          ticking = false;
        });
        ticking = true;
      }
      // setHeight(newHeight);
    };

    window.addEventListener('scroll', callback);
    // console.log(window.screenY);

    return () => window.removeEventListener('scroll', callback);
  });

  let visibleSectionNo = Math.floor(
    (scrollPosition + windowHeight / 2.3) / windowHeight
  );

  return (
    <>
      <div className="content" onClick={handleIntroClick}>
        <Intro />
        <Education />
        <Projects />
        <Contact />
      </div>
      <div className="progress" style={{ height: `${height}px` }}>
        <span>
          {titles[visibleSectionNo]}
          {titles[visibleSectionNo] === 'Portfolio' && <i>(selected)</i>}
          {/* {window.visualViewport.pageTop > window.visualViewport.height * 2
            ? titles[1]
            : titles[0]} */}
        </span>
      </div>
      <div className="spacer"></div>
    </>
  );
};

export default LandingPage;
