import { FaLinkedin, FaGithubSquare } from 'react-icons/fa';
import './style.scss';

const Intro = () => {
  return (
    <div className="section intro">
      <h2>Sylvester Bartnicki</h2>
      <p>React Developer</p>
      <p>
        A recent graduate of Fanshawe's Web Development and Internet
        Applications program. Focused on React development. Eager to apply his
        knowledge in a professional setting and contribute to a team of skilled
        developers.
      </p>
      <div className="iconsBox">
        <a href="https://www.linkedin.com/in/sylvester-bartnicki-277a46167/">
          <FaLinkedin />
        </a>
        <a href="https://github.com/sbartnicki">
          <FaGithubSquare />
        </a>
      </div>
    </div>
  );
};

export default Intro;
