import './style.scss';

const Education = () => {
  return (
    <div className="section education">
      <div className="eduItem">
        <h2>Fanshawe College</h2>
        <p>
          <i>Graduated April 2023</i>
        </p>
        <p>Web Development and Internet Applications</p>
        <ul>
          <li>React, React Native, basics of Anuglar and Typescript</li>
          <li>MySQL, Firebase, MongoDB</li>
          <li>Docker, Git, Jira, Vercel, Heroku</li>
          <li>PHP, Wordpress</li>
          <li>UX/UI design principles, Adobe Suite</li>
        </ul>
      </div>
      <div className="eduItem">
        <h2>CodersCamp</h2>
        <p>
          <i>Completed October 2019</i>
        </p>
        <p>Full Stack Development Bootcamp, run by CodersCrew non-profit</p>
        <ul>
          <li>HTML5, CSS3, JS ES6</li>
          <li>React, Redux, MongoDB</li>
          <li>Node.js, Express.js</li>
        </ul>
      </div>
    </div>
  );
};

export default Education;
