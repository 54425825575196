import {
  FaLinkedin,
  FaGithubSquare,
  FaPhoneSquareAlt,
  FaReact,
  FaRegCopyright,
} from 'react-icons/fa';
import './style.scss';

const Contact = () => {
  return (
    <div className="section contact">
      <div className="row"></div>
      <div className="row">
        <h2>Contact</h2>
        <p>
          If you're interested in connecting please reach out through one of the
          below
        </p>
        <div className="iconsBox">
          <a href="https://www.linkedin.com/in/sylvester-bartnicki-277a46167/">
            <FaLinkedin />
          </a>
          <a href="https://github.com/sbartnicki">
            <FaGithubSquare />
          </a>
          <a href="tel:+16478557576">
            <FaPhoneSquareAlt />
          </a>
        </div>
      </div>
      <div className="row">
        <footer>
          <p>
            Developed with React <FaReact />
          </p>
          <p>
            Copyright <FaRegCopyright /> 2023 Sylvester Bartnicki. All rights
            reserved.
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Contact;
